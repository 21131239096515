import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import './Header.scss';
import { Layout, Avatar, Menu, Dropdown } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, PoweroffOutlined } from '@ant-design/icons';

const { Header: HeaderANTD } = Layout;

@inject('AuthStore')
@observer
class Header extends Component {

    toggleMenu = () => {
        const { toggleMenu } = this.props;
        toggleMenu();
    }

    logOff = () => {
        const { AuthStore } = this.props;
        AuthStore.singOut();
    }

    render() {
        const { collapsed } = this.props;
        const menu = (
            <Menu className="dropdown-avatar-headera" onClick={this.logOff}>
                <Menu.Item key="1" icon={<PoweroffOutlined />}>logout</Menu.Item>
            </Menu>
        );
        return (
            <HeaderANTD className={collapsed ? ("header-home-collapsed") : ("header-home")} style={{ padding: 0 }}>
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: this.toggleMenu,
                })}
                <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']} arrow >
                    <Avatar className="avatar-header" overlay={menu} icon={<UserOutlined />} />
                </Dropdown>
            </HeaderANTD>
        )
    }
}

export default Header;