import React, {Component} from 'react';
import '../../../../components/RegisterCar/RegisterCar.scss';
import FormRegisterCar from '../../../../components/FormRegisterCar';
import { Layout } from 'antd';

const { Content } = Layout;

class UpdateCar extends Component{
    async componentDidMount (){
        const { formRef, car } = this.props;
        formRef.current.setFieldsValue({
            brand: car.brand,
            model: car.model,
            year: car.year,
            color: car.color,
            plate: car.plate,
            owner: car.Customer.name
        });
    }

    render (){
        const { 
            formRef,
            onCustomerSearch,
            handleState,
            update,
            customers
        } = this.props; 

        return (
        <Content className='content-car-registration'>
            <label className='label-car-registration'>Atualização de carro</label>
            <FormRegisterCar 
                formRef={formRef}
                onCustomerSearch={onCustomerSearch}
                handleState={handleState}
                register={update}
                customers={customers} 
            />
        </Content>);
    }
}

export default UpdateCar;