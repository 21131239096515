import React, { Component } from 'react';
import './Filter.scss';
import { Layout, Select as SelectANTD } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import Button from '../../../../components/button';
import DatePicker from "../../../../components/datePicker";
import Select from "../../../../components/select";

const { Content } = Layout;
const { Option } = SelectANTD;

class Filter extends Component {
    render() {
        const { 
            handleSearch,
            onChangeDate,
            handleCustomerSearch,
            onSelectCustomer,
            handleState,
            customers,
            cars
         } = this.props;
        return (
            <Content className="content-filter">
                <Select 
                    name="customer" 
                    placeholder="Cliente" 
                    onChange={(value, e) => onSelectCustomer(e.name, value)} 
                    onSearch={handleCustomerSearch} 
                > 
                    {customers.map(item => <Option value={item.id} name="customerId"  >{item.name}</Option>)}
                </Select>
                <Select 
                    name="car" 
                    placeholder="Carro" 
                    onChange={(value, e) => handleState(e.name, value)} 
                > 
                    {cars.map(item => <Option value={item.id} name="carId" >{item.model} | {item.color} | {item.plate}</Option>)}
                </Select>
                <DatePicker name="startDate" placeholder="Data inicial" onChange={e => onChangeDate('serviceDateStart', e)} />
                <DatePicker name="endDate" placeholder="Data final" onChange={e => onChangeDate('serviceDateEnd', e)} />
                <Button type="primary" onClick={handleSearch} ><SearchOutlined /></Button>
            </Content>
        );
    }
}

export default Filter;