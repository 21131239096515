import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Layout, Descriptions } from 'antd';
import Filter from './components/Filter';
import Card from '../../components/Card';
import Modal from '../../components/modal';
import ContentOpenModal from '../../components/ContentOpenModal';
import RegisterCar from '../../components/RegisterCar';
import UpdateCar from './components/UpdateCar';
import { openNotification } from '../../components/notification/Notification';
import PerfectScrollBar from 'react-perfect-scrollbar';

const { Content } = Layout;

@inject('CarsStore', 'CustomersStore')
@observer
class ContentCars extends Component {
    formRef = React.createRef();

    state = {
        isModalVisible: false,
        isUpdateModalVisible: false,
        data: [],
        car: {},
        carId: 0,
        customers: [],
        customerId: '',
        brand: '',
        model: '',
        year: 0,
        color: '',
        plate: '',
        owner: ''
    }

    async componentDidMount() {
        await this.getCarDetail();
    }

    getCarDetail = async () => {
        const { CarsStore } = this.props;
        const data = await CarsStore.search();
        this.setState({ data: data });
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        });
    }

    closeModal = () => {
        this.setState({
            isModalVisible: false
        });
    }

    showUpdateModal = () => {
        this.handleState("isUpdateModalVisible", true);
    }

    closeUpdateModal = () => {
        this.resetRegisterState();
        this.formRef.current.resetFields();
        this.handleState("isUpdateModalVisible", false);
    }

    onCardClick = (item) => {
        if (this.formRef.current != null)
            this.setFormRef(item);

        this.setupState(item);

        this.showUpdateModal();
    }

    setFormRef = (item) =>{
        this.formRef.current.setFieldsValue({
            brand: item.brand,
            model: item.model,
            year: item.year,
            color: item.color,
            plate: item.plate,
            owner: item.Customer.name
        });
    }

    setupState = (item) => {
        this.handleState("car", item);
        this.handleState("brand", item.brand);
        this.handleState("model", item.model);
        this.handleState("year", item.year);
        this.handleState("color", item.color);
        this.handleState("plate", item.plate);
        this.handleState("owner", item.Customer.id);
        this.handleState("carId", item.id);
    }

    handleCustomerSearch = async (value) => {
        if (value.length >= 3) {
            const id = ''
            const { CustomersStore } = this.props;
            const customers = await CustomersStore.search(id, value);
            this.setState({ customers });
        }
    }

    handleState = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    handleSearch = async () => {
        const { customerId } = this.state;
        const { CarsStore } = this.props;
        const id = '';
        const data = await CarsStore.search(id, customerId);
        this.setState({ data });
    }

    register = async () => {
        const payload = this.preparePayload()
        const { CarsStore } = this.props;
        const { data, status } = await CarsStore.register(payload);
        if (status == 200){
            this.resetRegisterState();
            this.formRef.current.resetFields();
            this.closeModal();
            this.handleSearch();
            openNotification("Ok", "Carro cadastrado com sucesso", true);
        }else{
            openNotification("Oops", `Erro ao cadastrar o carro. ${data.message}.`, false);
        }
    }

    preparePayload (){
        const { brand, model, year, color, plate, owner: customerId } = this.state;

        return { brand, model, year, color, plate, customerId };
    }

    update = async () => {
        const payload = this.preparePayload();
        const { carId } = this.state;
        payload.id = carId;
        const { CarsStore } = this.props;
        const { data, status } = await CarsStore.update(payload);
        if (status == 200){
            this.resetRegisterState();
            this.formRef.current.resetFields();
            this.closeUpdateModal();
            this.handleSearch();
            openNotification("Ok", "Carro atualizado com sucesso", true);
        }else{
            openNotification("Oops", `Erro ao atualizar o carro. ${data.message}.`, false);
        }
    }

    resetRegisterState = () => {
        this.handleState('brand', '');
        this.handleState('model', '');
        this.handleState('year', '');
        this.handleState('color', '');
        this.handleState('plate', '');
        this.handleState('owner', '');
    } 

    handleScroll = () => {
        const { CarsStore } = this.props;
        const hasMore = CarsStore.getHasMore();
        if (hasMore) {
            this.loadMore();
        }
    }

    loadMore = async () => {
        const { CarsStore } = this.props;
        const data = await CarsStore.loadMore();
        this.setState({ data });
    }

    render() {
        const {
            isModalVisible,
            isUpdateModalVisible,
            data,
            customers,
            car
        } = this.state;
        return (
            <Content >
                <Filter
                    handleCustomerSearch={this.handleCustomerSearch}
                    handleState={this.handleState}
                    handleSearch={this.handleSearch}
                    customers={customers}
                />
                <ContentOpenModal showModal={this.showModal} />
                <Modal visible={isModalVisible} onCancel={this.closeModal}>
                    <RegisterCar 
                        formRef={this.formRef}
                        onCustomerSearch={this.handleCustomerSearch}
                        handleState={this.handleState}
                        customers={customers}
                        register={this.register}
                    />
                </Modal>
                <Modal visible={isUpdateModalVisible} onCancel={this.closeUpdateModal}>
                    <UpdateCar 
                        formRef={this.formRef}
                        onCustomerSearch={this.handleCustomerSearch}
                        handleState={this.handleState}
                        customers={customers}
                        update={this.update}
                        car={car}
                    />
                </Modal>
                <Content className="content-card">
                    <PerfectScrollBar onYReachEnd={this.handleScroll} >
                        <ul>
                            {data.map(item => <Card title={item.model} onCardClick={this.onCardClick} id={item}> 
                                <Descriptions title="Informação do Carro" layout="horizontal">
                                    <Descriptions.Item label='Dono'>{item.Customer.name}</Descriptions.Item>
                                    <Descriptions.Item label='Placa'>{item.plate}</Descriptions.Item>
                                    <Descriptions.Item label='Cor'>{item.color}</Descriptions.Item>
                                    <Descriptions.Item label='Ano'>{item.year}</Descriptions.Item>
                                </Descriptions>
                            </Card>)}
                        </ul>
                    </PerfectScrollBar>
                </Content>
            </Content>
        )
    }
}

export default ContentCars;