import React, { Component } from 'react';
import { Input as InputANTD } from 'antd';
import './Input.scss'

class Input extends Component {
    render() {
        return(
            <InputANTD className='input' title={this.props.title} placeholder={this.props.placeholder} {...this.props} />
        );
    }
}

export default Input;