import request from './Request';

class Order {
    getOrders = async({ customerId = '', carId = '', serviceDateStart = '', serviceDateEnd = '', page, size }) => {
        try {
            const response = await request.get(`/services/search?customerId=${customerId}&carId=${carId}&serviceDateStart=${serviceDateStart}&serviceDateEnd=${serviceDateEnd}&page=${page}&size=${size}`);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    getAllOrders = async() => {
        try {
            const response = await request.get(`/services/search`);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    getOrderDetals = async(id) =>{
        try {
            const response = await request.get(`/services/details?serviceId=${id}`);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    orderRegister = async (payload) => {
        try {
            const response = await request.post('/services', payload);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    orderUpdate = async ({ id, currentKm = '', partsValue = '', laborValue = '', serviceDate = '', description = '', observation = '' }) => {
        try {
            const response = await request.patch('/services', { id, currentKm, partsValue, laborValue, serviceDate, description, observation });
            return response;
        } catch (error) {
            return error.response;
        }
    }
}

const orderServices = new Order();
export default orderServices;