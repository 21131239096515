import React, { Component } from 'react';
import './Filter.scss';
import { Layout, Select as SelectANTD } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import Select from '../../../../components/select';
import Button from '../../../../components/button';

const { Content } = Layout;
const { Option } = SelectANTD;

class Filter extends Component {
    render() {
        const { 
            handleState,
            handleCustomerSearch,
            handleSearch,
            customers
        } = this.props;
        return (
            <Content className="content-filter-car">
                <Select 
                    placeholder="Dono"
                    name="customer" 
                    onChange={(value, e) => handleState(e.name, value)} 
                    onSearch={handleCustomerSearch}  
                > 
                    {customers.map(item => <Option value={item.id} name="customerId" >{item.name}</Option>)}
                </Select>
                <Button type='primary' onClick={handleSearch} ><SearchOutlined /></Button>
            </Content>
        )
    }
}

export default Filter;