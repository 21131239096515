import { notification } from 'antd';
import { WarningOutlined, CheckOutlined } from '@ant-design/icons';


export const openNotification = (title, message, success) => {
    let finalIcon;
    if(success === true ){
        finalIcon = <CheckOutlined style={{ color: 'green' }} />
    }else {
        finalIcon = <WarningOutlined style={{ color: 'red' }} />
    }
    notification.open({
      message: title,
      description:
        message,
      icon: finalIcon,
      duration: 7,
    });
  };