import { observable, action } from 'mobx';
import Cars from '../services/Car';

class CarsStore {
    @observable cars = [];
    @observable hasMore = false;

    filter = {
        id: '',
        customerId: '',
        page: 1,
        size: 10
    }

    @action
    search = async ( id, customerId ) => {
        this.filter.page = 1;
        this.mountFilter({ id, customerId });
        const { data, status } = await Cars.carSearch(this.filter);
        if ( status == 200 ){
            this.cars = data.cars;
            this.hasMore = data.hasMore;
        }else{
            this.cars = [];
            this.hasMore = false;
        }
        return this.cars;
    }

    @action
    loadMore = async () => {
        this.filter.page +=1;
        const { data, status } = await Cars.carSearch(this.filter);
        if ( status == 200 ){
            this.cars = this.cars.concat(data.cars);
            this.hasMore = data.hasMore;
        }
        return this.cars;
    }

    register = async (payload) => {
        const { data, status } = await Cars.carRegister(payload);
        return { status, data };
    }

    update = async (payload) => {
        const { data, status } = await Cars.updateCar(payload);
        return { data, status };
    }

    mountFilter = ({ id, customerId }) => {
        this.filter = { id, customerId, page: this.filter.page, size: this.filter.size };
    }

    getHasMore = () => {
        return this.hasMore;
    }
}

const store = new CarsStore();
export default store;