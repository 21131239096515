import React, {Component} from 'react';
import FormRegisterService from '../../../../components/FormRegisterService';
import { Layout} from 'antd';
import moment from 'moment';

const { Content } =  Layout;

class RegisterService extends Component{
    
    componentDidMount (){
        const { formRef, service, customer, car } = this.props;
        const serviceDate = moment(service.serviceDate, 'YYYY-MM-DD');
        formRef.current.setFieldsValue({
            customer: customer.name,
            car: `${car.model} | ${car.color} | ${car.plate}`, 
            currentKm:  service.currentKm,
            partsValue: service.partsValue,
            laborValue: service.laborValue,
            serviceDate: serviceDate,
            description: service.description,
            observation: service.observation
        });
        
    }

    render(){
        const { 
            formRef,
            onChangeDate,
            handleState,
            update,
            disable
        } = this.props; 
        return(
            <Content className='content-order-registration'>
                <label className='label-order-registration'>Atualização de serviço</label>
                <FormRegisterService 
                    formRef={formRef}
                    onChangeDate={onChangeDate}
                    register={update}
                    handleState={handleState}
                    customers={[]} 
                    cars={[]} 
                    disable={disable}
            />
            </Content>
        )
    }
}

export default RegisterService;