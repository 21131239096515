import React, {Component} from 'react';
import './RegisterCar.scss';
import FormRegisterCar from '../FormRegisterCar';
import { Layout} from 'antd';

const { Content } =  Layout;

class RegisterCar extends Component{
    render(){
        const { 
            formRef,
            onCustomerSearch,
            handleState,
            register,
            customers
        } = this.props;
        return(
            <Content className='content-car-registration'>
                <label className='label-car-registration'>Cadastro de carro</label>
                <FormRegisterCar 
                    formRef={formRef}
                    handleState={handleState} 
                    customers={customers} 
                    onCustomerSearch={onCustomerSearch}
                    register={register} 
                />
            </Content>
        )
    }
}

export default RegisterCar;