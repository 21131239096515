import React, { Component } from 'react';
import { Card as CardANTD} from 'antd';
import './Card.css'

class Card extends Component {

    handleClick = () =>{
        const { onCardClick, id } = this.props;
        onCardClick(id);
    }

    render() {
        return (
            <CardANTD 
                className="home-card" 
                onClick={this.handleClick}
                {...this.props}/>
        );
    }
}

export default Card;