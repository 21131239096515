import React, { Component } from 'react';
import { Button as ButtonANTD } from 'antd';
import './Button.scss'

class Button extends Component {
    render() {
        return(
            <ButtonANTD className='button' title={this.props.title} {...this.props} />
        );
    }
}

export default Button;