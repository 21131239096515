import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Form.css';

class FormLogin extends Component {
    state = {
        loadings: []
    }

    enterLoading = index => {
        this.setState(({ loadings }) => {
            const newLoadings = [...loadings];
            newLoadings[index] = true;

            return {
                loadings: newLoadings,
            };
        });
        setTimeout(() => {
            this.setState(({ loadings }) => {
                const newLoadings = [...loadings];
                newLoadings[index] = false;

                return {
                    loadings: newLoadings,
                };
            });
        }, 6000);
    };

    render() {
        const { loadings } = this.state;
        return (
            <Form
                name="login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={this.props.onFinish}
            >
                <Form.Item
                    className="login-form-input"
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira o usuário!',
                        },
                    ]}
                >
                    <Input size="large" prefix={<UserOutlined className="site-form-item-icon" width="300px" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira a senha!',
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item>
                    <Button size="large" type="primary" htmlType="submit" className="login-form-button" loading={loadings[0]} onClick={() => this.enterLoading(0)}>
                        Login
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default FormLogin;