import { observable, action } from 'mobx';
import services from '../services/Order';

class ServicesStore {
    @observable services = [];
    @observable hasMore = false;
    
    filter = {
        customerId: '', 
        carId: '', 
        serviceDateStart: '', 
        serviceDateEnd: '',
        page: 1,
        size: 10
    }

    @action
    search = async ({ customerId, carId, serviceDateStart, serviceDateEnd }) => {
        this.filter.page = 1;
        this.mountFilter({ customerId, carId, serviceDateStart, serviceDateEnd })
        const { data, status } = await services.getOrders(this.filter);
        if ( status == 200 ){
            this.services = data.services;
            this.hasMore = data.hasMore;
        }else{
            this.hasMore = false;
            this.services = [];
        }
        return this.services;
    }

    @action
    getAllOrders = async() => {
        this.filter.page = 1;
        const { data, status } = await services.getAllOrders();
        if ( status == 200 ){
            this.services = data.services;
            this.hasMore = data.hasMore;
        }else{
            this.hasMore = false;
            this.services = [];
        }
        return this.services;
    }

    @action
    loadMore = async () => {
        this.filter.page +=1;
        const { data, status } = await services.getOrders(this.filter);
        if ( status == 200 ){
            this.services = this.services.concat(data.services);
            this.hasMore = data.hasMore;
        }
        return this.services;
    }

    register = async (payload) => {
        const { data, status } = await services.orderRegister(payload);
        return { data, status };
    }

    update = async ( payload ) => {
        const { data, status } = await services.orderUpdate(payload);
        return { data, status };
    }

    getHasMore = () => {
        return this.hasMore;
    }

    mountFilter = ({customerId, carId, serviceDateStart, serviceDateEnd}) => {
        this.filter = { customerId, carId, serviceDateStart, serviceDateEnd, page: this.filter.page, size: this.filter.size };
    }
}

const store = new ServicesStore();
export default store;