import React, { Component } from 'react';
import './FormRegisterCar.scss';
import Input from '../input';
import Button from '../button';
import Select from '../select';
import { Form, Select as SelectANTD } from 'antd';

const { Option } = SelectANTD;

const brands = ['VOLKSWAGEN', 'CHEVROLET', 'RENAULT', 'NISSAN', 'PEUGEOT', 'CITROEN', 'FIAT', 'BWM', 'FORD', 'KIA', 'AUDI', 'MERCEDES', 'HYUNDAI', 'HONDA', 'SUBARU', 'TOYOTA'];

class FormRegisterCar extends Component {
    render() {
        const {
            formRef,
            onCustomerSearch,
            handleState,
            register,
            customers
        } = this.props;
        return (
            <Form
                className="form-car-registration"
                name="car-registration"
                onFinish={register}
                ref={formRef}
                initialValues={{
                    remember: true,
                }}>
                <Form.Item
                    name="brand"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira a marca!',
                        },
                    ]}>
                    <Select 
                        name="brand"
                        placeholder="Marca do carro"
                        onChange={(value, e) => handleState(e.name, value)} >
                        {brands.map(item => <Option value={item} name="brand" >{item}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="model"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira o modelo!',
                        },
                    ]}>
                    <Input name="model" placeholder="Modelo do carro" onChange={e => handleState(e.target.name, e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="year"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira o ano!',
                        },
                    ]}>
                    <Input name="year" placeholder="Ano do carro" onChange={e => handleState(e.target.name, e.target.value)} />
                </Form.Item>
                <Form.Item name="color">
                    <Input name="color" placeholder="Cor do carro" onChange={e => handleState(e.target.name, e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="plate"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira a placa!',
                        },
                    ]}>
                    <Input name="plate" placeholder="Placa do carro" onChange={e => handleState(e.target.name, e.target.value)} />
                </Form.Item>
                <Form.Item
                    name="owner"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira o dono!',
                        },
                    ]}>
                    <Select
                        name="owner"
                        placeholder="Dono do carro"
                        onSearch={onCustomerSearch}
                        onChange={(value, e) => handleState(e.name, value)}
                    >
                        {customers.map(item => <Option value={item.id} name="owner" >{item.name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit">Cadastrar</Button>
                </Form.Item>
            </Form>
        )
    }
}

export default FormRegisterCar;