import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Layout } from 'antd';
import Filter from './components/Filter';
import Card from '../../components/Card';
import Modal from '../../components/modal';
import RegisterCustomer from '../../components/RegisterCustomer';
import UpdateCustomer from './components/UpdateCustomer';
import ContentOpenModal from '../../components/ContentOpenModal';
import { openNotification } from '../../components/notification/Notification';
import PerfectScrollBar from 'react-perfect-scrollbar';

const { Content } = Layout;

@inject('CustomersStore')
@observer
class ContentCustomers extends Component {
    formRef = React.createRef();

    state = {
        isModalVisible: false,
        isUpdateModalVisible: false,
        data: [],
        customers: [],
        customerName: '',
        id: 0
    }

    async componentDidMount() {
        await this.getCustomerDetail();
    }

    getCustomerDetail = async () => {
        const { CustomersStore } = this.props;
        const data = await CustomersStore.search();
        this.setState({ data });
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        });
    }

    closeModal = () => {
        this.setState({
            isModalVisible: false
        });
    }

    showUpdateModal = () => {
        this.setState({ isUpdateModalVisible: true });
    }

    closeUpdateModal = () => {
        this.setState({ isUpdateModalVisible: false });
    }

    onCardClick = (item) => {
        this.setupState(item);
        
        this.showUpdateModal();
    }

    
    setupState = (item) => {
        this.setState({ customerName: item.name });
        this.setState({ id: item.id });
    }

    handleCustomerSearch = async (value) => {
        if (value.length >= 3) {
            await this.handleSearch(value, 'customers')
        }
    }

    handleSearch = async (value, stateName) => {
        const id = '';
        const { CustomersStore } = this.props;
        const customers = await CustomersStore.search(id, value);
        this.setState({ [stateName]: customers });
    }

    handleSelect = () => {
        const { customers } = this.state;
        this.setState({ data: customers });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    register = async () => {
        const { CustomersStore } = this.props;
        const { customerName } = this.state;
        const { data, status } = await CustomersStore.register(customerName);
        if (status == 200){
            this.setState({ customerName: '' });
            this.formRef.current.resetFields();
            this.closeModal();
            this.handleSearch('', 'data');
            openNotification("Ok", "Cliente cadastrado com sucesso", true);
        }else{
            openNotification("Oops", `Erro ao cadastrado cliente. ${data.message}.`, false);
        }
    }


    update = async () => {
        const payload = this.preparePayload();
        const { CustomersStore } = this.props;
        const { data, status } = await CustomersStore.update(payload);
        if (status == 200){
            this.handleSearch(payload.name, 'data');
            this.resetState();
            this.formRef.current.resetFields();
            this.closeUpdateModal();
            openNotification("Ok", "Cliente atualizado com sucesso", true);
        }else{
            openNotification("Oops", `Erro ao atualizar cliente. ${data.message}.`, false);
        }
    }

    preparePayload = () => {
        const { id, customerName: name } = this.state;

        return {id, name};
    }

    resetState = () => {
        this.setState({ customerName: '' });
        this.setState({ id: '' });
    }

    handleScroll = () => {
        const { CustomersStore } = this.props;
        const hasMore = CustomersStore.getHasMore();
        if (hasMore) {
            this.loadMore();
        }
    }

    loadMore = async () => {
        const { CustomersStore } = this.props;
        const data = await CustomersStore.loadMore();
        this.setState({ data });
    }

    render() {
        const {
            isModalVisible,
            isUpdateModalVisible,
            data,
            customers,
            customerName
        } = this.state;
        return (
            <Content >
                <Filter
                    handleCustomerSearch={this.handleCustomerSearch}
                    handleSelect={this.handleSelect}
                    handleSearch={this.handleSearch}
                    customers={customers}
                />
                <ContentOpenModal showModal={this.showModal} />
                <Modal visible={isModalVisible} onCancel={this.closeModal}>
                    <RegisterCustomer 
                        formRef={this.formRef}
                        handleChange={this.handleChange} 
                        onFinish={this.register} 
                    />
                </Modal>
                <Modal visible={isUpdateModalVisible} onCancel={this.closeUpdateModal}>
                    <UpdateCustomer 
                        formRef={this.formRef}
                        handleChange={this.handleChange} 
                        onFinish={this.update}
                        customerName={customerName}
                    />
                </Modal>
                <Content className="content-card">
                    <PerfectScrollBar onYReachEnd={this.handleScroll} >
                        <ul> 
                            { data.map(item => <Card title={ item.name } id={ item } onCardClick={this.onCardClick} />) }
                        </ul>
                    </PerfectScrollBar>
                </Content>
            </Content>
        )
    }
}

export default ContentCustomers;