import axios from "axios";
import AuthStore from "../store/AuthStore";

const request = axios.create({
    baseURL: `http://mecanicalemos.tech`,
    httpsAgent: null
});

request.interceptors.request.use(async AxiosRequestConfig => {
    const config = AxiosRequestConfig;
    const token = AuthStore.getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

export default request;
