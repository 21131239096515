import React, { Component } from 'react';
import './FormRegisterService.scss';
import Button from '../button';
import Select from '../select';
import TextArea from '../textArea';
import DatePicker from '../datePicker';
import ContentOpenModal from '../ContentOpenModal';
import Modal from '../modal';
import RegisterCustomer from '../RegisterCustomer';
import RegisterCar from '../RegisterCar';
import { Form, Select as SelectANTD, InputNumber, Layout } from 'antd';

const { Option } = SelectANTD;
const { Content } = Layout;

class FormRegisterService extends Component {
    render() {
        const {
            formRef,
            formCustomerRef,
            formCarRef,
            handleCustomerSearch,
            onSelectCustomer,
            onChangeDate,
            handleState,
            handleChange,
            register,
            customers,
            cars,
            disable,
            isCustomerModalVisible,
            showCustomerModal,
            closeCustomerModal,
            customerRegister,
            isCarModalVisible,
            showCarModal,
            closeCarModal,
            carRegister
        } = this.props;
        return (
            <Form
                className="form-order-registration"
                name="order-registration"
                onFinish={register}
                ref={formRef}
                initialValues={{
                    remember: true,
                }}>
                <Modal visible={isCustomerModalVisible} onCancel={closeCustomerModal}>
                    <RegisterCustomer 
                        formRef={formCustomerRef}
                        handleChange={handleChange}
                        onFinish={customerRegister}
                        />
                </Modal>
                <Modal visible={isCarModalVisible} onCancel={closeCarModal}>
                    <RegisterCar
                        formRef={formCarRef}
                        handleState={handleState}
                        customers={customers}
                        register={carRegister}
                        onCustomerSearch={handleCustomerSearch}
                    />
                </Modal>
                <Content className="content-form">
                    <Form.Item
                        name="customer"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor insira o cliente!',
                            },
                        ]}
                    >
                        <Select
                            disabled={disable}
                            name="ownerId"
                            placeholder="Cliente"
                            onSearch={handleCustomerSearch}
                            onChange={(value, e) => onSelectCustomer(e.name, value)}
                            width="450px"
                        >
                            {customers.map(item => <Option value={item.id} name="ownerId"  >{item.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <ContentOpenModal disabled={disable} showModal={showCustomerModal}/>
                    </Form.Item>
                </Content>
                <Content className="content-form">
                    <Form.Item
                        name="car"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor insira o carro!',
                            },
                        ]}
                    >
                        <Select
                            disabled={disable}
                            name="newCarId"
                            placeholder="Carro"
                            onClick={this.onCarSearch}
                            onChange={(value, e) => handleState(e.name, value)}
                            width="450px"
                        >
                            {cars.map(item => <Option value={item.id} name="newCarId" >{item.model} | {item.color} | {item.plate}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <ContentOpenModal disabled={disable} showModal={showCarModal}/>
                    </Form.Item>
                </Content>
                <Content className="content-form">
                    <Form.Item name="partsValue" >
                        <InputNumber
                            value=""
                            formatter={value => value != "" ? `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                            parser={value => value.replace(/R\$\s?|(,*)/g, '')}
                            step={0.01}
                            min={0}
                            name="partsValue"
                            placeholder="valor de peças"
                            onChange={e => handleState("partsValue", e)}
                        />
                    </Form.Item>
                    <Form.Item name="laborValue" >
                        <InputNumber
                            value=""
                            formatter={value => value != "" ? `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}
                            parser={value => value.replace(/R\$\s?|(,*)/g, '')}
                            step={0.01}
                            min={0}
                            name="laborValue"
                            placeholder="valor de mão-de-obra"
                            onChange={e => handleState("laborValue", e)}
                        />
                    </Form.Item>
                </Content>
                <Content className="content-form">
                    <Form.Item name="currentKm" >
                        <InputNumber
                            value=""
                            formatter={value => value != "" ? `Km ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''}
                            parser={value => value.replace(/Km\s?|(\.*)/g, '')}
                            min={0}
                            name="currentKm"
                            placeholder="Quilometragem atual"
                            onChange={value => handleState("currentKm", value)}
                        />
                    </Form.Item>
                    <Form.Item name="serviceDate" >
                        <DatePicker
                            name="serviceDate"
                            placeholder="Data do serviço"
                            onChange={e => onChangeDate('serviceDate', e)}
                        />
                    </Form.Item>
                </Content>
                <Form.Item name="description" >
                    <TextArea
                        name="description"
                        placeholder="Descrição do serviço"
                        showCount
                        maxLength={2000}
                        onChange={e => handleState(e.target.name, e.target.value)}
                    />
                </Form.Item>
                <Form.Item name="observation">
                    <TextArea
                        name="observation"
                        placeholder="Observação do serviço"
                        showCount
                        maxLength={2000}
                        onChange={e => handleState(e.target.name, e.target.value)}
                    />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit">Cadastrar</Button>
                </Form.Item>
            </Form>
        )
    }
}

export default FormRegisterService;