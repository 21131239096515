import { observable, action } from 'mobx';
import Customers from '../services/Customer';

class CarsStore {
    @observable customers = [];
    @observable hasMore = false;

    filter={
        id: '',
        name: '',
        page: 1,
        size: 10
    }

    @action
    search = async ( id, name ) => {
        this.filter.page = 1;
        this.mountFilter({ id, name });
        const { data, status } = await Customers.customerSearch(this.filter);
        if (status == 200) {
            this.customers = data.customers;
            this.hasMore= data.hasMore;
        }else{
            this.customers = [];
            this.hasMore = false;
        }
        return this.customers;
    }

    @action
    loadMore = async () => {
        this.filter.page +=1;
        const { data, status } = await Customers.customerSearch(this.filter);
        if ( status == 200 ){
            this.customers = this.customers.concat(data.customers);
            this.hasMore = data.hasMore;
        }
        return this.customers;
    }

    register = async (name) => {
        const { data, status } = await Customers.customerRegister({ name });
        return { data, status };
    }

    update = async (payload) => {
        const { data, status } = await Customers.updateCustomer(payload);
        return { data, status };
    }

    getHasMore = () => {
        return this.hasMore;
    }

    mountFilter = ({id, name}) => {
        this.filter = { id, name, page: this.filter.page, size: this.filter.size };
    }

}

const store = new CarsStore();
export default store;