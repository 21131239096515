import request from './Request';

class Car {
    carRegister = async (payload) => {
        try {
            const response = await request.post('/cars', payload);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    updateCar = async (payload) => {
        try {
            const response = await request.patch('/cars', payload);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    carSearch = async ({ id = '', customerId = '', page = 1, size = 10 }) => {
        try {
            const response = await request.get(`/cars?id=${id}&customerId=${customerId}&page=${page}&size=${size}`);
            return response;
        } catch (error) {
            return error.response;
        } 
    }
}

const carServices = new Car();
export default carServices;