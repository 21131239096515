import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import AuthStore from './store/AuthStore'

const PrivateRoute = ({ component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            AuthStore.isAuthenticated() ? (
                <Component {...rest} { ...props } />
            ) : (
                <Redirect to={{pathname:'/login', state:{ from: props.locate }}}/>
            )
        }
    />
)

export default PrivateRoute;