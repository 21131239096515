import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './ContentServices.scss';
import { Layout, Descriptions } from 'antd';
import PerfectScrollBar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import Filter from './components/Filter';
import Card from '../../components/Card';
import Modal from '../../components/modal';
import RegisterService from './components/RegisterService';
import ContentOpenModal from '../../components/ContentOpenModal';
import moment, { relativeTimeThreshold } from 'moment';
import { openNotification } from '../../components/notification/Notification';

const { Content } = Layout;

@inject('ServicesStore', 'CarsStore', 'CustomersStore')
@observer
class ContentServices extends Component {
    formRef = React.createRef();
    formCustomerRef = React.createRef();
    formCarRef = React.createRef();

    state = {
        isModalVisible: false,
        data: [],
        customerId: '',
        carId: '',
        serviceDateStart: '',
        serviceDateEnd: '',
        customers: [],
        cars: [],
        ownerId: '',
        newCarId: '',
        currentKm: '',
        description: '',
        observation: '',
        partsValue: '',
        laborValue: '',
        serviceDate: "",
        isCustomerModalVisible: false,
        isCarModalVisible: false,
        brand: '',
        model: '',
        year: 0,
        color: '',
        plate: '',
        owner: '',
        customerName: ''
    }

    async componentDidMount() {
        await this.getServices();
    }

    getServices = async () => {
        const { ServicesStore } = this.props;
        const data = await ServicesStore.getAllOrders({});
        this.setState({ data });
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        });
    }

    closeModal = () => {
        this.setState({
            isModalVisible: false
        });
    }

    onCardClick = async (id) => {
        this.props.history.push(`service/details/${id}`);
    }

    handleState = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeDate = (name, value) => {
        if (value) {
            value = moment(value).format("YYYY-MM-DD");
            this.handleState(name, value);
        } else {
            this.handleState(name, '');
        }
    }

    onSelectCustomer = async (name, value) => {
        this.handleState(name, value);
        await this.handleCarSearch(value);
    }

    handleCarSearch = async (customerId) => {
        const { CarsStore } = this.props;
        const id = '';
        const cars = await CarsStore.search(id, customerId);
        this.setState({ cars });
    }

    handleSearch = async () => {
        const { ServicesStore } = this.props;
        const { customerId, carId, serviceDateStart, serviceDateEnd } = this.state;
        const data = await ServicesStore.search({ customerId, carId, serviceDateStart, serviceDateEnd });
        this.setState({ data });
    }

    handleCustomerSearch = async (value) => {
        if (value.length >= 3) {
            const id = ''
            const { CustomersStore } = this.props;
            const customers = await CustomersStore.search(id, value);
            this.setState({ customers });
        }
    }

    handleScroll = () => {
        const { ServicesStore } = this.props;
        const hasMore = ServicesStore.getHasMore();
        if (hasMore) {
            this.loadMore();
        }
    }

    loadMore = async (e) => {
        const { ServicesStore } = this.props;
        const data = await ServicesStore.loadMore();
        this.setState({ data });
    }

    register = async () => {
        const { newCarId: carId, ownerId: customerId, currentKm, description, observation, partsValue, laborValue, serviceDate } = this.state;
        const payload = {
            carId,
            customerId,
            currentKm,
            description,
            observation,
            partsValue,
            laborValue, 
            serviceDate
        }
        const { ServicesStore } = this.props;
        const { data, status } = await ServicesStore.register(payload);
        if (status == 200) {
            this.resetRegisterState();
            this.formRef.current.resetFields();
            this.closeModal();
            this.handleSearch();
            openNotification("Ok", "Serviço cadastrado com sucesso", true);
        }else {
            openNotification("Oops", `Erro ao cadastrar o serviço. ${data.message}.`, false);
        }
    }

    customerRegister = async () => {
        const { CustomersStore } = this.props;
        const { customerName } = this.state;
        const { data, status } = await CustomersStore.register(customerName);
        if (status == 200){
            this.setState({ customerName: '' });
            this.formCustomerRef.current.resetFields();
            this.closeCustomerModal();
            openNotification("Ok", "Cliente cadastrado com sucesso", true);
        }else{
            openNotification("Oops", `Erro ao cadastrado cliente. ${data.message}.`, false);
        }
    }

    carRegister = async () => {
        const payload = this.prepareCarPayload();
        const { CarsStore } = this.props;
        const { data, status } = await CarsStore.register(payload);
        if (status == 200){
            const { ownerId } = this.state;
            this.resetCarRegisterState();
            this.formCarRef.current.resetFields();
            this.handleCarSearch(ownerId);
            this.closeCarModal();
            openNotification("Ok", "Carro cadastrado com sucesso", true);
        }else{
            openNotification("Oops", `Erro ao cadastrar o carro. ${data.message}.`, false);
        }
    }

    prepareCarPayload (){
        const { brand, model, year, color, plate, owner: customerId } = this.state;

        return { brand, model, year, color, plate, customerId };
    }

    resetRegisterState = () => {
        this.handleState('newCarId','');
        this.handleState('ownerId','');
        this.handleState('currentKm','');
        this.handleState('description','');
        this.handleState('observation','');
        this.handleState('partsValue','');
        this.handleState('laborValue','');
        this.handleState('serviceDate','');
    }

    resetCarRegisterState = () => {
        this.handleState('brand', '');
        this.handleState('model', '');
        this.handleState('year', '');
        this.handleState('color', '');
        this.handleState('plate', '');
        this.handleState('owner', '');
    }

    showCustomerModal = () => {
        this.setState({ isCustomerModalVisible: true });
    }

    closeCustomerModal = () => {
        this.setState({ isCustomerModalVisible: false });
    }

    showCarModal = () => {
        this.setState({ isCarModalVisible: true });
    }

    closeCarModal = () => {
        this.setState({ isCarModalVisible: false });
    }

    render() {
        const {
            isModalVisible,
            isCustomerModalVisible,
            isCarModalVisible,
            data,
            customers,
            cars
        } = this.state;
        return (
            <Content>
                <Filter
                    handleSearch={this.handleSearch}
                    handleState={this.handleState}
                    onChangeDate={this.onChangeDate}
                    onSelectCustomer={this.onSelectCustomer}
                    handleCustomerSearch={this.handleCustomerSearch}
                    customers={customers}
                    cars={cars}
                />
                <ContentOpenModal showModal={this.showModal} />
                <Modal visible={isModalVisible} onCancel={this.closeModal}>
                    <RegisterService
                        formRef={this.formRef}
                        formCustomerRef={this.formCustomerRef}
                        formCarRef={this.formCarRef}
                        handleCustomerSearch={this.handleCustomerSearch}
                        onSelectCustomer={this.onSelectCustomer}
                        onChangeDate={this.onChangeDate}
                        handleState={this.handleState}
                        handleChange={this.handleChange}
                        register={this.register}
                        customers={customers}
                        cars={cars}
                        isCustomerModalVisible={isCustomerModalVisible}
                        showCustomerModal={this.showCustomerModal}
                        closeCustomerModal={this.closeCustomerModal}
                        customerRegister={this.customerRegister}
                        isCarModalVisible={isCarModalVisible}
                        showCarModal={this.showCarModal}
                        closeCarModal={this.closeCarModal}
                        carRegister={this.carRegister}
                    />
                </Modal>
                <Content className="content-card" >
                    <PerfectScrollBar onYReachEnd={this.handleScroll} >
                        <ul>
                        {data.map(item => <Card title={item.Customer.name} onCardClick={this.onCardClick} id={item.id} >
                            <Descriptions title="Informação do serviço" layout="horizontal">
                                <Descriptions.Item label='Modelo do carro'>{item.Car.model}</Descriptions.Item>
                                <Descriptions.Item label='Placa do carro'>{item.Car.plate}</Descriptions.Item>
                                <Descriptions.Item label='Data'>{moment(item.serviceDate).format("DD/MM/YYYY")}</Descriptions.Item>
                            </Descriptions>
                        </Card>)}
                        </ul>
                    </PerfectScrollBar>
                </Content>
            </Content>
        )
    }
}

export default ContentServices;