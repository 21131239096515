import React, {Component} from 'react';
import './RegisterService.scss';
import FormRegisterService from '../../../../components/FormRegisterService';
import { Layout} from 'antd';

const { Content } =  Layout;

class RegisterService extends Component{
    handleState = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    render(){
        const { 
            formRef,
            formCustomerRef,
            formCarRef,
            handleCustomerSearch,
            onSelectCustomer,
            onChangeDate,
            handleState,
            handleChange,
            register,
            customers,
            cars,
            isCustomerModalVisible,
            showCustomerModal,
            closeCustomerModal,
            customerRegister,
            isCarModalVisible,
            showCarModal,
            closeCarModal,
            carRegister
        } = this.props; 
        return(
            <Content className='content-order-registration'>
                <label className='label-order-registration'>Cadastro de serviço</label>
                <FormRegisterService 
                    formRef={formRef}
                    formCustomerRef={formCustomerRef}
                    formCarRef={formCarRef}
                    handleCustomerSearch={handleCustomerSearch} 
                    onSelectCustomer={onSelectCustomer}
                    onChangeDate={onChangeDate}
                    register={register}
                    handleState={handleState}
                    handleChange={handleChange}
                    handleChange={handleChange}
                    customers={customers} 
                    cars={cars} 
                    isCustomerModalVisible={isCustomerModalVisible}
                    showCustomerModal={showCustomerModal}
                    closeCustomerModal={closeCustomerModal}
                    customerRegister={customerRegister}
                    isCarModalVisible={isCarModalVisible}
                    showCarModal={showCarModal}
                    closeCarModal={closeCarModal}
                    carRegister={carRegister}
            />
            </Content>
        )
    }
}

export default RegisterService;