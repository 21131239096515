import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/login';
import ContentServices from './pages/ContentServices';
import ContentCustomers from './pages/ContentCustomers';
import ContentCars from './pages/ContentCars';
import ContentServiceDetails from './pages/ContentServiceDetails';
import PrivateRoute from './PrivateRoutes';

const Routes = ({ ...props }) => (
        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/">
                <Redirect to={{pathname:'/home'}}/>
            </Route>
            <PrivateRoute exact path="/home" component={ContentServices} {...props}/>
            <PrivateRoute exact path="/cars" component={ContentCars} {...props} />
            <PrivateRoute exact path="/customers" component={ContentCustomers} {...props} />
            <PrivateRoute path="/service/details" component={ContentServiceDetails} {...props} />
        </Switch>
)

export default Routes;