import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './PageDefault.scss';
import Routes from '../../Routes';
import Menu from '../Menu';
import Header from '../Header';
import { Layout } from 'antd';

const { Content } = Layout; 

@inject('AuthStore')
@observer
class PageDefault extends Component {
    state = {
        collapsed: false
    }

    toggleMenu = () => {
        this.setState(prevState => ({ collapsed: !prevState.collapsed }));
    };

    render() {
        const { collapsed } = this.state;
        const { AuthStore } = this.props;
        const isAuthenticated = AuthStore.isAuthenticated();
        return (
            <Layout>
                { isAuthenticated && <Menu collapsed={collapsed} /> }
                <Layout>
                    { isAuthenticated && <Header collapsed={collapsed} toggleMenu={this.toggleMenu} /> }
                    <Content className={collapsed ? ("content-home-space-collapsed") : ("content-home-space")} >
                        <Routes collapsed={collapsed} />
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default PageDefault;