import React, { Component } from 'react';
import './TextArea.scss';
import { Input } from 'antd';

const { TextArea: TextAreaANTD } = Input;

class TextArea extends Component {
    render() {
        return (
            <TextAreaANTD
                {...this.props}
            />
        )
    }
}

export default TextArea;