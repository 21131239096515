import React, { Component } from 'react';
import './ContentServiceDetails.scss';
import { inject, observer } from 'mobx-react';
import { Descriptions, Layout, Divider} from 'antd';
import Service from '../../services/Order';
import moment from 'moment';
import Button from '../../components/button';
import Modal from '../../components/modal';
import UpdateService from './components/updateService';
import { EditOutlined } from '@ant-design/icons';
import { openNotification } from '../../components/notification/Notification';

const { Content } = Layout;

@inject('ServicesStore')
@observer
class ContentServiceDetails extends Component {
    formRef = React.createRef();

    state = {
        isModalVisible: false,
        service: '',
        customer: '',
        car: '',
        currentKm: '',
        partsValue: '',
        laborValue: '',
        serviceDate: '',
        description: '',
        observation: ''
    }

    async componentDidMount() {
        await this.getServiceDetail();
        this.resetUpdateSates();
    }


    getServiceDetail = async () => {
        const url = window.location.pathname;
        const urlSplitted = url.split('/');
        const id = urlSplitted[3];
        const { data, status } = await Service.getOrderDetals(id);
        if (status == 200) {
            this.setState({ service: data.serviceData });
            this.setState({ customer: data.customerData });
            this.setState({ car: data.carData });
        }
    }

    formatDecimal = (value) => {
        value = parseFloat(value); 
        return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    showModal = () => {
        this.setState({
            isModalVisible: true
        });
    }

    closeModal = () => {
        this.setState({
            isModalVisible: false
        });
    }

    handleState = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    onChangeDate = (name, value) => {
        if (value) {
            value = moment(value).format("YYYY-MM-DD");
            this.handleState(name, value);
        } else {
            this.handleState(name, '');
        }
    }

    update = async () => {
        const { currentKm, partsValue, laborValue, serviceDate, description, observation, service } = this.state;
        const payload = {
            id: service.id,
            currentKm,
            partsValue,
            laborValue,
            serviceDate,
            description,
            observation
        }
        const { ServicesStore } = this.props;
        const { data , status } = await ServicesStore.update(payload);
        if (status == 200) {
            await this.getServiceDetail();
            this.closeModal();
            this.formRef.current.resetFields();
            this.resetUpdateSates();
            openNotification('Ok', 'Serviço atualizado com sucesso!', true);
        }else {
            openNotification('Oops', `Erro ao atualiza serviço. ${data.message}.`, false);
        }
    }

    resetUpdateSates = () => {
        const { service } = this.state;
        this.handleState('currentKm', service.currentKm);
        this.handleState('partsValue', service.partsValue);
        this.handleState('laborValue', service.laborValue);
        this.handleState('serviceDate', service.serviceDate);
        this.handleState('description', service.description);
        this.handleState('observation', service.observation);
    }
    
    render() {
        const {
            isModalVisible,
            service,
            customer,
            car
        } = this.state;
        return (
            <Content className="content-service-details">
                <Content className="content-title" > 
                    <label className="title" > Detalhes do serviços </label>
                    <Button onClick={this.showModal} ><EditOutlined /></Button>
                </Content>
                <Modal visible={isModalVisible} onCancel={this.closeModal} >
                    <UpdateService 
                        formRef={this.formRef}
                        handleState={this.handleState}
                        onChangeDate={this.onChangeDate}
                        update={this.update}
                        service={service}
                        customer={customer}
                        car={car}
                        disable={true}
                    />
                </Modal>
                <Divider plain >Cliente</Divider>
                <Descriptions layout="vertical" >
                    <Descriptions.Item label='Cliente'>{customer.name}</Descriptions.Item>
                </Descriptions>
                <Divider plain >Carro</Divider>
                <Descriptions layout="vertical" >
                    <Descriptions.Item label='Marca'>{car.brand}</Descriptions.Item>
                    <Descriptions.Item label='Modelo'>{car.model}</Descriptions.Item>
                    <Descriptions.Item label='Ano'>{car.year}</Descriptions.Item>
                    <Descriptions.Item label='Placa'>{car.plate}</Descriptions.Item>
                    <Descriptions.Item label='Cor'>{car.color}</Descriptions.Item>
                </Descriptions>
                <Divider plain >Serviço</Divider>
                <Descriptions layout="vertical" >
                    <Descriptions.Item label='Data'>{moment(service.serviceDate).format("DD/MM/YYYY")}</Descriptions.Item>
                    <Descriptions.Item label='Quilometragem'>{service.currentKm}</Descriptions.Item>
                    <Descriptions.Item label='Valor das Peças'>{this.formatDecimal(service.partsValue)}</Descriptions.Item>
                    <Descriptions.Item label='Valor da mão-de-obra'>{this.formatDecimal(service.laborValue)}</Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" >
                    <Descriptions.Item label='Descrição'>{service.description}</Descriptions.Item>
                </Descriptions>
                <Descriptions layout="vertical" >
                    <Descriptions.Item label='Observações'>{service.observation}</Descriptions.Item>
                </Descriptions>
            </Content>
        );
    }
}

export default ContentServiceDetails;