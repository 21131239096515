import { observable, action } from 'mobx';
import jwtDecode from 'jwt-decode';
import AuthServices from '../services/Auth';

export class AuthStore {
    @observable status = '';

    @action
    singIn = async (payload) => {
        try {
            const { data } = await AuthServices.authenticate(payload);
            const { token } = data;
            const { username } = jwtDecode(token);

            localStorage.setItem('token', token);
            localStorage.setItem('user', username);

            this.status = 'SUCCESS';
        } catch (error) {
            this.status= 'ERROR';
            console.error(this.status, error);
        }
        return this.status;
    }

    singOut = () => {
        this.removeToken();
        this.removeUser();
        window.location.assign('/');
    }

    isAuthenticated = () => {
        const token = localStorage.getItem('token');
        if(!token)
            return false;
        else   
            return true;
    }

    getToken = () => localStorage.getItem('token');
    getUser = () => localStorage.getItem('user');

    removeToken = () => localStorage.removeItem('token');
    removeUser = () => localStorage.removeItem('user');
}

const authStore = new AuthStore();
export default authStore;