import React, { Component } from 'react';
import './Select.scss';
import { Select as SelectANTD } from 'antd';

class Select extends Component {
    render() {
        const {
            placeholder,
            width
        } = this.props;
        const widhtValue = width == "450px"? "450px" : "250px";
        return (
            <SelectANTD
                showSearch
                placeholder={placeholder}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{width : widhtValue}}
                {...this.props}
            />
        )
    }
}

export default Select