import React, { Component } from 'react';
import './Filter.scss';
import { Layout, Select as SelectANTD } from 'antd'
import Select from '../../../../components/select';

const { Content } = Layout;
const { Option } = SelectANTD;

class Filter extends Component {
    render() {
        const { 
            handleSelect,
            handleCustomerSearch,
            handleSearch,
            customers
        } = this.props;
        return (
            <Content className="content-filter-customers">
                <Select 
                    placeholder="Nome"
                    name="customer" 
                    allowClear='true'
                    onSelect={handleSelect}
                    onClear={handleSearch}
                    onSearch={handleCustomerSearch}  
                > 
                    {customers.map(item => <Option value={item.id} name="customerId" >{item.name}</Option>)}
                </Select>
            </Content>
        )
    }
}

export default Filter;