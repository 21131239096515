import React, { Component } from 'react';
import './FormRegisterCustomer.scss';
import Input from '../input';
import Button from '../button'
import { Form } from 'antd';

class FormCustomerRegistration extends Component {
    render() {
        const {
            formRef,
            handleChange,
            onFinish
        } = this.props;
        return (
            <Form 
                className="form-customer-registration"
                name="customer-registration" 
                onFinish={onFinish}
                ref={formRef}
                initialValues={{
                    remember: true,
                }}>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor insira nome do cliente!',
                        },
                    ]}>
                    <Input name="customerName" onChange={e => handleChange(e)} placeholder="Nome do cliente"/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit">Cadastrar</Button>
                </Form.Item>
            </Form>
        )
    }
}

export default FormCustomerRegistration;