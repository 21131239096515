import React, { Component } from 'react';
import { DatePicker as DatePickerANTD } from 'antd';
import './DatePicker.scss'
import locale from 'antd/es/date-picker/locale/pt_BR';

class DatePicker extends Component {
    render() {
        return(
            <DatePickerANTD 
                className="DatePicker"
                locale={locale}
                format="DD/MM/YYYY"
                {...this.props}
            />
        );
    }
}

export default DatePicker;