import React, { Component } from 'react';
import './Modal.scss';
import { Modal as ModalANTD } from 'antd';

class Modal extends Component {
    render (){
        return(
            <ModalANTD 
                className="modal"
                footer={null}    
                {...this.props}
            />
        )
    }
}

export default Modal;