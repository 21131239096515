import React, { Component } from 'react';
import './RegisterCustomer.scss';
import { Layout} from 'antd';
import FormRegisterCustomer from '../FormRegisterCustomer';

const { Content } = Layout;

class CustomerRegistration extends Component {
    render() {
        const { 
            formRef,
            onFinish,
            handleChange
        } = this.props;
        return (
            <Content className="content-customer-registration">
                <label className="label-customer-registration">Cadastro de cliente</label>
                <FormRegisterCustomer 
                    formRef={formRef}
                    onFinish={onFinish}
                    handleChange={handleChange}
                />
            </Content>
        )
    }
}

export default CustomerRegistration;