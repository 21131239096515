import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import './Login.css'
import { Image, Space } from 'antd';
import FormLogin from './components/form/Form';
import logo from '../../image/logo.png';
import { openNotification } from '../../components/notification/Notification';
import { withRouter } from 'react-router-dom';


@inject('AuthStore')
@observer
class Login extends Component {
  state= {
    isLogging: false,
    loginStatus: '',
  }

  onFinish = async (values) => {
    const { AuthStore } = this.props;
    const { username, password } = values;

    const login = await AuthStore.singIn({ username, password });

    if(login === 'SUCCESS'){
      window.location.assign('/home');
    }else if(login){
      openNotification("Oops", "Usuário e/ou senha inválido", false);
    }
  };
  
  render() {
    return (
      <Space className="login-space">
        <Image className="login-image"
          width={300}
          src={logo}
        />
        <FormLogin onFinish={this.onFinish} />
      </Space>
    );
  }
};

export default withRouter(Login);