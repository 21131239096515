import React, { Component } from 'react';
import '../../../../components/RegisterCustomer/RegisterCustomer.scss';
import { Layout} from 'antd';
import FormRegisterCustomer from '../../../../components/FormRegisterCustomer';

const { Content } = Layout;

class UpdateCustomer extends Component {
    async componentDidMount (){
        this.setField();
    } 

    async componentDidUpdate (){
        this.setField();
    }
    
    setField = () =>{
        const { formRef, customerName } = this.props;
        formRef.current.setFieldsValue({ name: customerName });
    }

    render (){
        const { 
            formRef,
            onFinish,
            handleChange
        } = this.props;

        return(
            <Content className="content-customer-registration">
                <label className="label-customer-registration">Atualização de cliente</label>
                <FormRegisterCustomer 
                    formRef={formRef}
                    onFinish={onFinish}
                    handleChange={handleChange}
                />
            </Content>
        );
    }
}

export default UpdateCustomer;