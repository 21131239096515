import React, { Component } from 'react';
import './Menu.scss';
import { Layout, Menu as MenuANTD } from 'antd'
import { CarOutlined, UserOutlined , ToolOutlined } from '@ant-design/icons';
import logo from '../../image/logo.png';
import { withRouter } from 'react-router-dom';

const { Sider } = Layout;

class Menu extends Component {
    goTo = (page, ) => {
        this.props.history.push(page);
    }

    render() {
        const { collapsed } = this.props;
        return (
            <Sider className="menu" trigger={null} collapsible collapsed={collapsed}>
                <img className={collapsed ? ("logo-menu-collapsed") : ("logo-menu")} src={logo} />
                <MenuANTD theme="dark" mode="inline" defaultSelectedKeys={['1']} >
                    <MenuANTD.Item key="1" icon={<ToolOutlined />} onClick={() => this.goTo("/home")}>
                        Serviços
                        </MenuANTD.Item>
                    <MenuANTD.Item key="2" icon={<UserOutlined />} onClick={() => this.goTo("/customers")}>
                        Clientes
                        </MenuANTD.Item>
                    <MenuANTD.Item key="3" icon={<CarOutlined />} onClick={() => this.goTo("/cars")}>
                        Carros
                        </MenuANTD.Item>
                </MenuANTD>
            </Sider>
        );
    }
}

export default withRouter(Menu);