import React, { Component } from 'react';
import './ContentOpenModal.scss';
import { Layout } from 'antd';
import Button from '../button';
import { FileAddOutlined  } from '@ant-design/icons';

const { Content } = Layout;

class ContentOpenModal extends Component {

    handleClick = () => {
        const { showModal } = this.props;
        showModal();
    }

    render() {
        const { disabled } = this.props
        return (
            <Content className="content-open-modal" >
                <Button onClick={this.handleClick} disabled={disabled}><FileAddOutlined /></Button>
            </Content>
        )
    }
}

export default ContentOpenModal;