import request from './Request';

class Customer {
    customerRegister = async (payload) => {
        try {
            const response = await request.post('/customers', payload);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    updateCustomer = async (payload) => {
        try {
            const response = await request.patch('/customers', payload);
            return response;
        } catch (error) {
            return error.response;
        }
    }

    customerSearch = async ({ id = "", name = "", page = 1, size = 10 }) => {
        try {
            const response = await request.get(`/customers?id=${id}&name=${name}&page=${page}&size=${size}`);
            return response;
        } catch (error) {
            return error.response;
        }
    }
}

const customerServices = new Customer();
export default customerServices;