import request from './Request';

class Auth {
    authenticate = async(body) => {
        try {
            const response = await request.post('/auth', body);
            return response;
        } catch (error) {
            return error.response;
        }
    }
}

const authServices = new Auth();
export default authServices;
