import './App.css';
import PageDefault from './components/PageDefault';
import { Provider } from 'mobx-react';

import stores from './store';

const App = () => (
    <Provider  {...stores}>
        <PageDefault />
    </Provider>
);

export default App;